import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import { Icon } from "@iconify/react";
import { CoreTimeclock } from "../../providers/Timeclock";
import { TIME_ICON, TIME_ICON_DEFAULT_COLOR } from "../../constances/icons";
import { AnyBadge } from "../AnyBadge";
import { MarkdownView } from "../../MarkdownView";

export const TimeclockItem = ({ timeclock } : { timeclock: CoreTimeclock }) => {
    const end = timeclock.end ? new Date(timeclock.end) : new Date();
    const start = timeclock.start ? new Date(timeclock.start) : new Date();
    const hoursWorked = (end.getTime() - start.getTime()) / 3600000;
    return <Accordion>
       
        <AccordionSummary>
        <Box sx={{ display: 'flex', alignItems: 'center' , paddingRight: 1 , color : TIME_ICON_DEFAULT_COLOR }}>
            <Icon icon={TIME_ICON} />
        </Box>
            <AnyBadge name={`${timeclock.user_firstName} ${timeclock.user_lastName}`} colorkey={timeclock.user_email||undefined} />
        <Typography sx={{ paddingLeft: 1, whiteSpace: 'nowrap' }}>
                    {new Date(timeclock.start).toLocaleString('en-US', { month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false }).replace(",", "")}
                </Typography>
        <Typography sx={{fontWeight: 'bold', marginLeft: 1}}> Time Entery ({hoursWorked}h)</Typography></AccordionSummary>
        <AccordionDetails><MarkdownView>{timeclock.message}</MarkdownView></AccordionDetails>
    </Accordion>
    
    
}