import { DocumentNode, gql, useMutation, useQuery } from "@apollo/client"
import { FC, useEffect, useMemo, useState } from "react"
import { StyledTable, TableColumnDef } from "../Table";
import { getGraphqlEndpoints, mapGraphqlGenericColumns,  mapGraphqlGenericColumnsToInnerSelect } from "../../utils/graphql.generics";
import { GraphqlGenericColumn } from "../../types/graphql.generics";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { GraphqlNewItemDialog } from "../GraphqlNewItemDialog/GraphqlNewItemDialog";
import { Icon } from "@iconify/react";
import { DELETE_ICON, EDIT_ICON } from "../../constances/icons";
import { GraphqlEditItemDialog } from "../GraphqlEditItemDialog/GraphqlEditItemDialog";



type ColumnProp = string  | GraphqlGenericColumn ;


function splitUpperCamelCase(input: string): string {
    return input.replace(/([a-z])([A-Z])/g, '$1 $2');
}

const ActionColumn : FC<{
    onDelete: (id: string) => void,
    onEdit: (id: string) => void,
    row : any,
    disableDelete?: boolean,
    disableEdit?: boolean
}> = ({onDelete, onEdit, row, disableDelete, disableEdit}) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton onClick={() => onDelete(row)} disabled={disableDelete}>
                    <Icon icon={DELETE_ICON} />
                </IconButton>
                <IconButton onClick={() => onEdit(row)} disabled={disableEdit}>
                    <Icon icon={EDIT_ICON} />
                </IconButton>
            </div>
        );
}

export const PaginatedGraphqlQueryTable : FC<{
    baseName: string,
    queryEndpoint?: string,
    createEndpoint?: string,
    editEndpoint?: string,
    getByIdEndpoint?: string,
    title?: string,
    columns: ColumnProp[]}  > = ({ baseName,   columns : columnProps , ...rest}) => {
     

    const { title, queryEndpoint, createEndpoint, deleteEndpoint , editEndpoint, getByIdEndpoint } = { 
        title: splitUpperCamelCase(baseName) ,  
        ...getGraphqlEndpoints(baseName), 
        ...rest 
    };
    const [ showCreateDialog, setShowCreateDialog ] = useState(false);
    const [ page, setPage ] = useState(0);
    const [ limit, setLimit ] = useState(25);
    const [ totalCount , setTotalCount ] = useState(0);
    const [ editItem , setEditItem ] = useState<Record<string, any> | null>(null);
    const [ deleteItem , setDeleteItem ] = useState<Record<string, any> | null>(null);
    const columns = useMemo(() => {
        return mapGraphqlGenericColumns(columnProps);
    }, [columnProps]);
    const query = useMemo(() => {
        return gql`
            query GetData${queryEndpoint}(  $page: Int, $limit: Int ) {
                ${queryEndpoint}( page : $page , limit : $limit ) {
                    data {
                        ${mapGraphqlGenericColumnsToInnerSelect(columns)}
                    }
                    page
                    totalCount
                    limit
                }
            }
        `;
    }, [queryEndpoint, columns]);
        

    const deleteQuery = useMemo(() => {
        const indexColumn = columns.find(column => column.primaryKey);
        console.log( `
            mutation Delete${queryEndpoint}(  $${indexColumn?.field} : ${indexColumn?.type || 'Int'}! ) {
                ${deleteEndpoint}( ${indexColumn?.field} : $${indexColumn?.field} ) 
            }
        ` );
        return gql`
            mutation Delete${queryEndpoint}(  $${indexColumn?.field} : ${indexColumn?.type || 'Int'}! ) {
                ${deleteEndpoint}( ${indexColumn?.field} : $${indexColumn?.field} ) 
            }
        `;
    }, [queryEndpoint, columns]);
    
    const [ deleteMutation, { loading: deleteLoading, error: deleteError } ] = useMutation(deleteQuery);

    const { data, loading, error , refetch } = useQuery(query, {  variables: { page, limit } });
    const styledColumns: TableColumnDef[] = useMemo(() => {
        return [ ...columns.filter(column => column.visible).map(column => {
            const extra : any = {};
            if( column.CustomView ){
                extra.Element = column.CustomView;
            }
            return {
            field: column.field,
            headerName: column.label || column.field,
            flex: 1,
            ...extra
            }
        }), { field: 'actions', type : 'actions', headerName: 'Actions', Element : ActionColumn as any , flex: 0 }];
    }, [JSON.stringify(columns)]);

    const usableData : any = useMemo(() => {
        return data?.[queryEndpoint]?.data || [];
    }, [JSON.stringify(data)]);
    useEffect( () => {
        if( data && data?.[queryEndpoint]  ){
            setTotalCount(data?.[queryEndpoint]?.totalCount || 0);
        }
    } , [data]);
    const onDelete = ( item : any ) => {
        setDeleteItem( item );
    }
    const onEdit = ( item : any ) => {
        setEditItem( item );
    }


    return <>
    { editItem && <GraphqlEditItemDialog 
        baseItem={editItem} 
        open={Boolean( editItem)} 
        baseName={baseName} 
        onClose={() => setEditItem(null)} 
        columns={columns} 
        editEndpoint={editEndpoint}
        afterSubmit={() => {
            setEditItem(null);
            refetch();
        }}
    /> }
    <Dialog open={!!deleteItem} onClose={() => setDeleteItem(null)}>
        <DialogTitle>Delete</DialogTitle>
        <DialogContent>
            Are you sure you want to delete this item?
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setDeleteItem(null)}>Cancel</Button>
            <Button variant="contained" color="error" onClick={() => {
                // Add your delete logic here
                deleteItem && deleteMutation({ variables: deleteItem }).then( () => {
                    setDeleteItem(null);
                    refetch();
                });
                
            }}>Delete</Button>
        </DialogActions>
    </Dialog>
    <StyledTable
        enablePagination
        disableFrontendSorting
        totalCount={totalCount}
        elementProps={{
            onDelete,
            onEdit
        }}
        page={page}
        limit={limit}
        setPage={setPage}
        setPageSize={setLimit}
        title={title} 
        actions={<><Button size="small" variant="contained" color="secondary" onClick={() => setShowCreateDialog(true)}>Add</Button></>} 
        columns={styledColumns} 
        rows={usableData} 
     />
    <GraphqlNewItemDialog 
        afterSubmit={() => {
            refetch();
        }}
        open={showCreateDialog} 
        baseName={baseName} 
        onClose={() => setShowCreateDialog(false)} 
        columns={columns} 
        createEndpoint={createEndpoint} 
    />
    </>
}