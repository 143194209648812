import { Checkbox, Dialog, DialogContent, DialogTitle, TextField, FormControlLabel, Grid, DialogActions, Button } from "@mui/material"
import { FC, useMemo, useState } from "react"
import { GraphqlGenericColumn } from "../../types/graphql.generics"
import { getGraphqlEndpoints, mapGraphqlGenericColumns, mapGraphqlGenericColumnsToInnerSelect } from "../../utils/graphql.generics"
import { gql, useMutation } from "@apollo/client"


const ColumnField : FC<{
    column: GraphqlGenericColumn
    value: any
    onChange: (value: any) => void
} > = ({column, value, onChange}) => {
    if( column.CustomEdit ){
        return <column.CustomEdit value={value} onSave={(value : any ) => onChange(value)} />
    }
    switch(column.type) {
        case 'String':
            return <TextField disabled={column.primaryKey} fullWidth label={column.label} value={value || ''} onChange={(e) => onChange(e.target.value)} />
        case 'Float':
        case 'Int':
            return <TextField  disabled={column.primaryKey} fullWidth type="number" label={column.label} value={value || ''} onChange={(e) => onChange(e.target.value)} />
        case 'Boolean':
            return <FormControlLabel
                control={<Checkbox  disabled={column.primaryKey} checked={value} onChange={(e) => onChange(e.target.checked)} />}
                label={column.label}
            />
        default:
            return <></>
    }
}


export const GraphqlEditItemDialog : FC<{
    open: boolean,
    baseItem: Record<string, any>,
    baseName: string,
    onClose: () => void,
    afterSubmit?: (data: any) => void
    columns: Array<GraphqlGenericColumn | string >
    editEndpoint?: string
} > = ({baseItem , open, baseName, onClose, afterSubmit, columns : columnProps, ...rest}) => {
    const { editEndpoint } = { ...getGraphqlEndpoints(baseName), ...rest };
    const columns = useMemo(() => {
        return mapGraphqlGenericColumns(columnProps);
    }, [columnProps]);

    
    const createItemGql = useMemo(() => {
        const propDef = columns.filter(column => column.visible).map(column => `$${column.field}: ${column.type}${column.primaryKey ? '!' : ''}`).join(', ');

        const columnsToProps = columns.filter(column => column.visible).map(column => `${column.field}: $${column.field}`).join(', ');
        return gql`
            mutation Edit${editEndpoint}(  ${propDef} ) {
                ${editEndpoint}( ${columnsToProps} ) {
                    ${mapGraphqlGenericColumnsToInnerSelect(columns)}
                }
            }
        `; 
    }, [editEndpoint, columns]);
    
    const [createItem, {loading, error}] = useMutation(createItemGql);
    
    const [formData, setFormData] = useState<Record<string, any>>(baseItem);
    const setFormDataField = (field: string, value: any) => {
        setFormData( fd => ({
            ...fd,
            [field]: value
        }));
    }
    const handleSubmit = async () => {
        const result = await createItem({ variables: formData });
        afterSubmit?.(result.data[editEndpoint]);
        onClose();
    }
    return <Dialog maxWidth="lg" open={open} onClose={onClose}>
        <DialogTitle>Create New Item</DialogTitle>
        <DialogContent>
            <Grid container spacing={2}>
            {columns.map((column) => (
                <Grid item sm={12} sx={{marginTop: 1}} ><ColumnField key={column.field} column={column} value={formData[column.field]} onChange={(value) => {
                    setFormDataField(column.field, value);
                }} /></Grid>
            ))}
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={handleSubmit}>Submit</Button>
        </DialogActions>
    </Dialog>
}