import { gql, useQuery } from "@apollo/client";
import { Container, MenuItem, TextField } from "@mui/material";
import { PaginatedGraphqlQueryTable } from "../../../react-submodules/components/PaginatedGraphqlQueryTable/PaginatedGraphqlQueryTable";
import { FC } from "react";

const GroupView : FC<{ cell : any , row : any , refresh?: () => any } & any > = ({ row}) => {
    return <div>{row?.group?.name}</div>
}

const UserView : FC<{ cell : any , row : any , refresh?: () => any } & any > = ({ row}) => {
    return <div>{row?.user?.firstName} {row?.user?.lastName} ({row?.user?.email})</div>
}

const GROUP_QUERY = gql`
query GetDatafindCoreGroupPaginated {
  findCoreGroupPaginated(page: 0, limit: 1000) {
    data {
      id
      name
    }
  }
}
`

const USER_QUERY = gql`
query GetDatafindCoreUsersPaginated {
  findCoreUsersPaginated(page: 0, limit: 1000) {
    data {
      id
      firstName
      lastName
      email
    }
  }
}
`

const GroupEdit : FC<{ value : any , onSave : any , refresh?: () => any } & any > = ({ value , onSave}) => {
    const { data , loading } = useQuery(GROUP_QUERY);

    if( loading || !data?.findCoreGroupPaginated?.data ) return <div>...loading</div>

    return <div>
        <TextField fullWidth label="Group" value={value} onChange={(e) => onSave(e.target.value)} select >
            {data?.findCoreGroupPaginated?.data?.map((group: any) => (
                <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
            ))}
        </TextField>
    </div>
}
const UserEdit : FC<{ value : any , onSave : any , refresh?: () => any } & any > = ({ value , onSave}) => {
    const { data , loading } = useQuery(USER_QUERY);

    if( loading || !data?.findCoreUsersPaginated?.data ) return <div>...loading</div>

    return <div>
        <TextField fullWidth label="User" value={value} onChange={(e) => onSave(e.target.value)} select >
            {data?.findCoreUsersPaginated?.data?.map((user: any) => (
                <MenuItem key={user.id} value={user.id}>{user.firstName} {user.lastName} ({user.email})</MenuItem>
            ))}
        </TextField>
    </div>
}

export const CoreGroupMembersConfig = () => {
    return  <Container>
        <PaginatedGraphqlQueryTable baseName="CoreGroupsMembers"  columns={[
            { field: 'id', type: 'Float', createRequired: false , primaryKey: true , autoIncrement : true}, 
            { label: 'Group', field: 'groupId', type: 'Float', createRequired: true , CustomView : GroupView , CustomEdit : GroupEdit }, 
            { label: 'User', field: 'coreUserId', type: 'String', createRequired: true , CustomView : UserView , CustomEdit : UserEdit },
            { field: 'group', type: 'CUSTOM', visible: false , select: true, selectString: `group { name }` },
            { field: 'user', type: 'CUSTOM', visible: false , select: true, selectString: `user { firstName , lastName , email }` },
            ]} />
    </Container>
}