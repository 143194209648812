import { GraphqlGenericColumn } from "../types/graphql.generics";

export const getGraphqlEndpoints = (baseName: string) => {
    return {
        queryEndpoint: `find${baseName}Paginated`,
        createEndpoint: `create${baseName}`,
        editEndpoint: `update${baseName}`,
        getByIdEndpoint: `get${baseName}ById`,
        deleteEndpoint: `delete${baseName}`
    }
}
const defaultColumn : GraphqlGenericColumn = {
    field: 'id',
    type: 'String',
    editable: false,
    visible: true,
    searchable: true
}

export const mapGraphqlGenericColumns = (columns: Array<GraphqlGenericColumn | string >) => {
    return columns.map(column => {
        if (typeof column === 'string') {
            return {
                field: column,
                type: 'String',
                editable: true,
                visible: true,
                label: column,
                searchable: true
            } as GraphqlGenericColumn;  
        }
        const label = column.label || column.field;
        return {
            label,
            ...defaultColumn,
            ...column
        };
    });
}
export const mapGraphqlGenericColumnsToInnerSelect= (columns: GraphqlGenericColumn[]) => {
    return columns.filter(column => column.visible || column?.select).map(column => column.selectString ? column.selectString : `${column.field}`).join('\n');
}