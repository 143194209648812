import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Switch, TextField, setRef } from "@mui/material";
import { FC, useEffect, useState } from "react";

import { StyledTable } from "../../react-submodules/components/Table"
import { useApi } from "../../react-submodules/providers/Api/Api";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { MarkdownView } from "../../react-submodules/MarkdownView";



const DateTimeFormat : (date: string) => string = (date) => {
    const lux = DateTime.fromISO(date).setZone("America/Chicago");
    return lux.toLocaleString(DateTime.DATETIME_SHORT);
}

const AudioPlayer : FC<{url: string}> = ({url}) => {
    return <audio controls src={url} />
}


export const Calls = () => {
    const nav = useNavigate();
    const api = useApi();
    const [ transcription , setTranscription ] = useState<string>(''    );
    const [ refreshTick , setRefresh ] = useState(1);
    const [ startDate , setStartDate ] = useState<string>(DateTime.now().minus({days: 1}).toFormat('yyyy-MM-dd'));
    const [ endDate , setEndDate ] = useState<string>(DateTime.now().toFormat('yyyy-MM-dd'));
    const [ calls , setCalls ] = useState<any[]>([]);
    const refresh = () => {
        setRefresh( r =>  r * -1 );
    }
    useEffect( () => {
        if( api.ready){
            api.get('/bvoip/search', {
                'ActualStartTime:gte': startDate ? DateTime.fromFormat(startDate, 'yyyy-MM-dd').startOf('day').toISO() : undefined,
                'ActualEndTime:lte': endDate ? DateTime.fromFormat(endDate, 'yyyy-MM-dd').endOf('day').toISO() : undefined
            }).then( ( { data}) => {
                setCalls(data);
            });
        }
    } , [api.ready , refreshTick , startDate , endDate ]);
    return (
        <div>
            <h1>Calls</h1>
            <Paper>
                <Grid sx={{p: 2}} container spacing={2}>
                    <Grid item sm={6}>
                        <TextField fullWidth label="Start Date" type="date" value={startDate} onChange={(e) => {
                            setStartDate(e.target.value);
                        }} />
                    </Grid>
                    <Grid item sm={6}>
                        <TextField fullWidth label="End Date" type="date" value={endDate} onChange={(e) => {
                            setEndDate(e.target.value);
                        }} />
                    </Grid>
                </Grid>
            </Paper>
            <StyledTable rows={calls} columns={[{
                field: 'CallID',
                headerName: 'ID',
                width: 100
            } , {
                field: 'ActualStartTime',
                headerName: 'Date',
                width: 100,
                Element: ({cell}) => <div>{DateTimeFormat(cell)}</div>
            } 
            , {
                field: 'OriginatedBy',
                headerName: 'Originated By',
                width: 100,
                Element: ({cell}) => <div>{cell}</div>
            }
            , {
                field: 'Destination',
                headerName: 'Destination',
                width: 100,
                Element: ({cell}) => <div>{cell}</div>
            }
            , {
                field: 'Transcription',
                headerName: 'Transcription',
                width: 100,
                Element: ({cell}) => <>{ cell && <Button onClick={() => {
                            setTranscription(cell);
                        }}>View</Button>
                    }
                </>
            },
            {
                field: 'localPath',
                headerName: 'File',
                width: 100,
                Element: ({cell}) => <>{cell && <AudioPlayer url={cell} />}</>
            } 
            
            ]} />
            <Dialog open={Boolean(transcription)} onClose={() => {
                setTranscription('');
            }}>
                <DialogTitle>Transcription</DialogTitle>
                <DialogContent>
                    <MarkdownView>{transcription}</MarkdownView>
                </DialogContent>
            </Dialog>
        </div>
    );
}