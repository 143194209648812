import { Alert, Box, Button, Checkbox, Grid, IconButton, InputAdornment, Popover, SxProps, Table, TableBody, TableCell, TableHead, TableRow, TextField, Theme, Typography, useTheme, Badge, TableProps, TablePagination } from "@mui/material";
import { DataGrid, GridColDef, gridClasses } from "@mui/x-data-grid";
import { ComponentType, FunctionComponent, useCallback, useEffect, useMemo } from "react";
import { alpha, styled } from '@mui/material/styles';

import { FilterMenu } from "./FilterMenu/FilterMenu";
import { InlineIcon } from "@iconify/react";
import { TableApiProvider } from "./Table.provider";
import { useFilter } from "../../providers/FilterProvider";
import { useState } from "react";

const ODD_OPACITY = 0.2;

export type TableColumnDef<T extends Record<string, any> = Record<string, any > , E = Record<string, any> > = GridColDef & {
  Element?: FunctionComponent<{ cell : any , row : T , refresh?: () => any } & E >;
  sortRemap?: ( cell : any , row : any ) => any;
  filter?: 'string' | 'number' | 'date' | 'options'
}

const DefaultStyledContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  '@media print': {
    zIndex: 999990,
    position: 'absolute',
    left: 0,
    top:0
  }
 } ) )
const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText
 } ) )
 const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  
 } ) )


 const sortToIcon = ( column : TableColumnDef ,  sort? : SortColumn[] ) => {
  const sorted = sort?.find( s => s.field === column.field );
  if ( !sorted ){
    return 'prime:sort';
  }
  if( sorted.direction === 'asc' ){
    return 'prime:sort-amount-up';
  }
  return 'prime:sort-amount-down';
 }

 

 const HeaderButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  width: '16px',
  height:'16px',
  padding: 0

 } ) )


 





 const SortButton = ( { column  } : { column : TableColumnDef} ) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const {filter} = useFilter( column.field );
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const filterIcon = useMemo( () => {
    if( !filter ){
      return 'mdi:filter-outline'
    }
    return 'mdi:filter'
  } , [filter] );
  const open = Boolean(anchorEl);
  const id = open ? 'filter-popover' : undefined;
  return <><HeaderButton size="small"  onClick={handleClick} ><InlineIcon icon={filterIcon} /></HeaderButton>
  <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        { open && <FilterMenu column={column} onClose={()=>{
          handleClose();
        }} /> }
      </Popover>
      </>
 }


const ColumnHeaderCell : FunctionComponent<{
  column: TableColumnDef,
  sort?: SortColumn[],
  toggleSort?: ( column : TableColumnDef , append?: boolean ) => void
}> = ( { column , sort , toggleSort }) => {
  const theme = useTheme();
  const icon = useMemo( () => {
    return sortToIcon(  column , sort );
  } , [JSON.stringify( sort ) , column.field]);
  const sx : SxProps<Theme> = useMemo( () => {
    if( !column.minWidth ){
      return {}
    }
    return {
      minWidth: `${column.minWidth}px`
    }
  } , [ column.minWidth || 'none']);

  

  return <StyledTableHeadCell sx={sx}  >{column.headerName || column.field}
    <Box sx={{display: 'inline-block', marginLeft: '3px'}}>
      { column.sortable && <HeaderButton size="small" onClick={( e ) => { toggleSort && toggleSort( column , e.altKey || e.metaKey )}} ><InlineIcon icon={icon} /></HeaderButton>}
      {column.filter && <SortButton column={column} /> }</Box></StyledTableHeadCell>
}


const StyledHeader : FunctionComponent<{
  columns: Array<TableColumnDef>,
  sort?: SortColumn[],
  toggleSort?: ( column : TableColumnDef ) => void
  actions?: JSX.Element;
  title?: string;
  hideHeader? : boolean;
  bulkItemsLength?  : number;
  enableBulkActions?: boolean
  executeBulkAction?: ( ) => any
  setAllBulkActions?: ( checked : boolean ) => void
  allBulkedChecked?: boolean
}> = ( { columns , sort , toggleSort , title , actions , hideHeader , enableBulkActions , setAllBulkActions , allBulkedChecked , bulkItemsLength , executeBulkAction }) => {
  
 return <StyledTableHead >
    {!hideHeader &&<TableRow>
      <StyledTableHeadCell colSpan={columns.length + (enableBulkActions ? 1 : 0)} >
       <Grid container spacing={2}>
        <Grid item flex={1} >{title && <Typography variant="h5"  >{title}</Typography>}</Grid>
        
      <Grid item flex={0}>
        {actions && <Box>{actions}</Box>}
        
      </Grid>
      <Grid item flex={0}>
        { Boolean( bulkItemsLength ) && <Box>
          <Badge badgeContent={bulkItemsLength} color="info" sx={{
            marginLeft: '5px'
          }}>
            <Button variant="contained" size="small" color="secondary" sx={{
              whiteSpace: 'nowrap'
            }} onClick={()=>{
                executeBulkAction && executeBulkAction();
            }}>Bulk Actions</Button>
          </Badge>
        </Box>}
        </Grid>
      </Grid> 
        </StyledTableHeadCell> 
    </TableRow> }
    <TableRow>
    {enableBulkActions && <StyledTableHeadCell sx={{
      color: 'white'
    }}  ><Checkbox color="secondary" checked={allBulkedChecked} onChange={(e)=>{
      setAllBulkActions && setAllBulkActions( e.target.checked );
    }} /></StyledTableHeadCell> }
      {columns.map( ( column  , i ) => {
        return <ColumnHeaderCell key={`${column.field}-${i}`} column={column} sort={sort} toggleSort={toggleSort}/>
      } )}
      </TableRow></StyledTableHead>
}

const DefaultElement : TableColumnDef['Element'] = ( { cell , row }) => {
  return <>{cell}</>
}


export type SortColumn = {
  field: string,
  direction: 'asc' | 'desc'
}

const getSortableValue = <T extends Record<string,any> = Record<string, any> >( column : TableColumnDef<T> , cell : any , row : any ) => {
  if ( column.sortRemap ){
    return column.sortRemap( cell , row );
  }
  return cell;
}
export const StyledTable = <T extends Record<string,any> = Record<string, any> > ( 
  { 
    Container : PropStyledContainer , enablePagination , disableFrontendSorting, hideHeader , refresh, rows, columns , indexField , onClick , title ,elementProps , actions , defaultSort , 
    enableBulkActions , onBulkAction , onCheckedChange , sort : sortProp , 
    setSort : setSortProp ,
    totalCount: totalCountProp,
    page: pageProp,
    limit: limitProp,
    setPageSize: setPageSizeProp,
    setPage: setPageProp,
    ...props } : 
{
  rows: T[],
  columns: Array<TableColumnDef<T>>,
  onClick?: ( row : any ) => void,
  indexField?: string;
  refresh?: () => any;
  title?: string;
  actions?: JSX.Element;
  elementProps?: Record<string, any>,
  defaultSort?:  SortColumn[],
  hideHeader?: boolean;
  enableBulkActions?: boolean
  onBulkAction?: ( rows : T[] ) => any
  onCheckedChange?: ( checkedItems : T[] ) => any,
  sort?: SortColumn[]
  setSort?: ( sort : SortColumn[] ) => void
  disableFrontendSorting?: boolean
  enablePagination?: boolean
  Container?: ComponentType<{ children: React.ReactNode }>
  totalCount?: number
  page?: number
  limit?: number
  setPageSize?: ( pageSize : number ) => void
  setPage?: ( page : number ) => void
} & TableProps
) => { 
  const StyledContainer = PropStyledContainer || DefaultStyledContainer;
  const theme = useTheme();
  const [ pageInternal , setPageInternal ] = useState( 0 );
  const [ pageInternalSize , setPageSizeInternal ] = useState( 25 );
  const setPage = ( page : number ) => {
    if( typeof setPageProp !== 'undefined' ){
      setPageProp( page );
    } else {
      setPageInternal( page );
    }
  }
  const setPageSize = ( pageSize : number ) => {
    setPageSizeInternal( pageSize );
    setPageSizeProp && setPageSizeProp( pageSize );
  }
  const page = useMemo( () => {
    if( typeof pageProp !== 'undefined' ){
      return pageProp;
    }
    return pageInternal;
  } , [pageProp , pageInternal] );
  const pageSize = useMemo( () => {
    if( typeof limitProp !== 'undefined' ){
      return limitProp;
    }
    return pageInternalSize;
  } , [limitProp , pageInternalSize] );
  const totalCount = useMemo( () => {
    if( typeof totalCountProp !== 'undefined' ){
      return totalCountProp;
    }
    return rows.length;
  } , [totalCountProp , rows.length] );
  const [ checkedItemIndexes , setCheckedItemIndexes ] = useState<Array<string|number>>([]);

  const checkItem = ( index : string | number ) => {
    setCheckedItemIndexes( ci =>  [ ...ci , index ] );
  }
  const uncheckItem = ( index : string | number ) => {
    setCheckedItemIndexes( ci => ci.filter( i => i !== index ) );
  }
  const isChecked = ( index : string | number ) => {
    return checkedItemIndexes.includes( index );
  }
  
  const [ sortCore , setSortCore ] = useState<SortColumn[]>(defaultSort || []);

  useEffect( () => {
    if( page  * pageSize >= totalCount ){
      setPage( 0 );
    }
  } , [ page , pageSize ,totalCount ]);

  const sort = useMemo( () => {
    if( typeof sortProp !== 'undefined' ){
      return sortProp;
    }
    return sortCore;
  } , [ sortCore , sortProp ]);
  const setSort = ( sort : SortColumn[] ) => {
    if( typeof setSortProp !== 'undefined' ){
      setSortProp( sort );
      return;
    }
    setSortCore( sort );
  }
  

  const toggleSort = (column : TableColumnDef , append?: boolean ) => {
    const currentColumn = sort.find( s => s.field === column.field );
    if ( !append ){
      setSort( [ { field: column.field , direction: currentColumn?.direction === 'asc' ? 'desc' : 'asc' } ] );
      return;
    }
    if ( !currentColumn ){
      setSort( [ ...sort , { field: column.field , direction:  'asc'  } ] );
      return;
    }
    setSort( sort.map( s => {
      if ( s.field !== column.field ){
        return s;
      }
      return { ...s , direction: s.direction === 'asc' ? 'desc' : 'asc' };
    } ) );
  }

  const sortedRows = useMemo( () => {
    if( disableFrontendSorting ){
      return rows;
    }
    try {

    
    return rows.sort( ( a , b ) => {
      for( const sortColumn of sort ){
        const column = columns.find( c => c.field === sortColumn.field );
        if ( !column ){
          continue;
        }
        const aVal = getSortableValue( column ,  a[sortColumn.field]  , a );
        const bVal =  getSortableValue( column , b[sortColumn.field] , b );
        if ( aVal < bVal ){
          return sortColumn.direction === 'asc' ? -1 : 1;
        }
        if ( aVal > bVal ){
          return sortColumn.direction === 'asc' ? 1 : -1;
        }
      }
      return 0;

    });
  } catch ( e ){
    console.error( e );
    return rows;
  }
  } , [ JSON.stringify( rows) , JSON.stringify( sort ) , disableFrontendSorting ]);

  const pageRows = useMemo( () => {
    if( !enablePagination || typeof totalCountProp === 'number' ){
      return sortedRows;
    }
    return sortedRows.slice( (page )*pageSize , (page + 1 )*pageSize );
  } , [ JSON.stringify( sortedRows ) , page , pageSize ]);
  const setAllBulkActions = ( checked : boolean ) => {
    if( checked && indexField ){
      setCheckedItemIndexes( rows.map( ( row  ) => row[indexField] ) );
    } else {
      setCheckedItemIndexes( [] );
    }
  }
  const allBulkedChecked = useMemo( () => {
    return rows.length === checkedItemIndexes.length;
  } , [ rows.length , checkedItemIndexes.length ]);
  const executeBulkAction = () => {
    if( !indexField){
      return;
    }
      const checkedItems = sortedRows.filter( ( row , index ) => row[indexField] && checkedItemIndexes.includes( row[indexField] ) );
      onBulkAction && onBulkAction( checkedItems );
  }
  if( !indexField && enableBulkActions){
    return <Alert severity="error">Index field is required for bulk actions</Alert>
  }

  return <TableApiProvider refresh={async () =>{ refresh && await refresh() } } ><StyledContainer  >
    
    
    <Table {...props} >
      <StyledHeader 
        hideHeader={hideHeader} 
        executeBulkAction={executeBulkAction}
        actions={actions} 
        bulkItemsLength={checkedItemIndexes.length}
        columns={columns as any } sort={sort} toggleSort={toggleSort} title={title} enableBulkActions={enableBulkActions} 
        setAllBulkActions={setAllBulkActions}
        allBulkedChecked={allBulkedChecked}
        />
      <TableBody  sx={{background: theme.palette.background.paper}}>
        {pageRows.map( ( row , index ) => {
          const key = (indexField? row[indexField] : index ) || index;
          return <TableRow onClick={()=>{
            onClick && onClick( row );
          }} key={key}>
            {enableBulkActions && <TableCell><Checkbox  checked={isChecked(key)} onChange={(e)=>{
              if( e.target.checked ){
                checkItem( key );
              } else {
                uncheckItem( key );
              }
            }} /></TableCell>}
            {columns.map( ( column , i ) => {
              
              const { Element } = { Element : DefaultElement ,  ...column };
              
              return <TableCell key={`${column.field}-${i}`}><Element {...(elementProps||{})} refresh={ refresh } cell={row[column.field]} row={row} /></TableCell>  
            } )}
          </TableRow>

        } )}

      </TableBody>
  </Table>
 
  </StyledContainer>
  {enablePagination && (
    <Box sx={{
      background: theme.palette.background.paper,
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
    }}>
      <TablePagination
        component="div"
        sx={{
          background: theme.palette.background.paper,
        }}
        count={totalCount}
        page={page}
        rowsPerPage={pageSize}
        onPageChange={(e, newPage) => {
          setPage(newPage);
        }}
        onRowsPerPageChange={(e) => {
          setPageSize(parseInt(e.target.value));
        }}
      />
    </Box>
  )}
  </TableApiProvider>
} 